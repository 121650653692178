/* Ensure no default spacing around the page */
html, body {
    margin: 0;
    padding: 0;
    height: 100%; /* Ensure body takes full height */
  }
  
  /* Background container */
  .landing-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    text-align: center;
    background-image: url('../crowd3.jpg'); /* Replace with your image path */
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent tiling */
    position: relative;
    color: #fff;
    margin: 0; /* Remove any unwanted margin */
    padding: 0;
    overflow: hidden; /* Prevent scrollbars due to image overflow */
  }
  
  /* Overlay to darken background */
  .landing-page-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  /* Content container */
  .landing-page-content {
    position: relative;
    z-index: 2;
    padding: 0 16px; /* Ensures spacing for smaller screens */
  }
  
  /* Title styling */
  .landing-page-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
  
  /* Subtitle styling */
  .landing-page-subtitle {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
  }
  
  /* Button styling */
  .landing-page-button {
    background-color: #ff7300;
    color: #fff;
    font-weight: bold;
    padding: 15px 40px;
    border-radius: 30px;
    box-shadow: 0 4px 20px rgba(255, 87, 34, 0.5);
    transition: all 0.3s ease-in-out;
    border: none; /* Ensure no borders */
    cursor: pointer;
  }

  .landing-page-disconnect-button {
    position: fixed; /* Keeps it in the same spot relative to the viewport */
    bottom: 20px; /* Space from the top */
    right: 20px; /* Space from the right */
    font-weight: bold;
    background-color: #ff7300;
    box-shadow: 0 4px 20px rgba(255, 87, 34, 0.5);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    z-index: 3; /* Ensure it's above the overlay */
    transition: background-color 0.3s ease; /* Smooth hover effect */
  }
  
  .landing-page-disconnect-button:hover {
    background-color: #b71c1c; /* Darker red on hover */
  }
  
  .header {
    background-color: #000; /* Black background */
    padding: 80px 20px; /* Padding around the content */
    display: flex; /* Use flexbox for alignment */
    justify-content: space-between; /* Space between logo and button */
    align-items: center; /* Center items vertically */
    position: fixed; /* Fix the header at the top */
    top: 0; /* Stick to the top */
    width: 100%; /* Full width */
    z-index: 1000; /* Stay on top of other elements */
    box-shadow: 0px 41px 25px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
  }

  .landing-page-button:hover {
    background-color: #e64a19;
    box-shadow: 0 6px 30px rgba(255, 87, 34, 0.7);
  }
  
  .landing-page-logo {
    position: absolute;
    top: 32.9px; /* Adjust spacing from the top */
    left: 50%;
    transform: translateX(-50%);
    z-index: 2; /* Ensure it stays above the overlay */
  }
  
  .landing-page-logo svg {
    width: 300px; /* Adjust logo size */
    height: auto;
  }

  .landing-page-footer {
    position: relative;
    z-index: 3; /* Ensure it's above the overlay */
    text-align: center;
    margin-top: 20px;
    padding: 10px;
    color: white;
  }



  
  .footer-link {
    color: #e64a19; /* Spotify Green */
    text-decoration: none;
    font-weight: bold;
    margin: 0 5px;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }

  .landing-page-back-button {
    position: fixed; /* Keeps it in the same spot relative to the viewport */
    bottom: 20px; /* Space from the top */
    right: 20px; /* Space from the right */
    font-weight: bold;
    background-color: #ff7300;
    box-shadow: 0 4px 20px rgba(255, 87, 34, 0.5);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    z-index: 3; /* Ensure it's above the overlay */
    transition: background-color 0.3s ease; /* Smooth hover effect */
  }
  
  .landing-page-back-button:hover {
    background-color: #b71c1c; /* Darker red on hover */
  }

  .landing-page-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .landing-page-steps {
    list-style-type: decimal;
    padding: 0;
    margin: 0 auto;
    max-width: 600px;
    text-align: left;
    font-size: 16px;
    line-height: 1.6;
  }
  
  .landing-page-steps li {
    margin-bottom: 10px;
  }
  
  .landing-page-steps a {
    color: #ff7300; /* Spotify green */
    text-decoration: none;
  }
  
  .landing-page-steps a:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    .landing-page-steps {
      padding-left: 40px; /* Increase padding for small screens */
      font-size: 14px; /* Slightly smaller text for better fit */
    }
  }

/* Modal Styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay for better contrast */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above everything else */
}

.modal-content {
  background-color: #1e1e1e; /* Dark background to match your theme */
  padding: 30px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%; /* Responsive width */
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
  color: #fff; /* White text for contrast */
}

.modal-content h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Consistent with your title styling */
}

.modal-content p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.modal-content label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

.modal-content input[type="checkbox"] {
  margin-right: 10px;
  cursor: pointer;
}

.modal-content button {
  background-color: #ff7300; /* Orange to match your button styling */
  color: #fff;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 20px rgba(255, 87, 34, 0.5); /* Consistent with your button styling */
}

.modal-content button:disabled {
  background-color: #ccc; /* Grayed out when disabled */
  cursor: not-allowed;
  box-shadow: none;
}

.modal-content button:hover:not(:disabled) {
  background-color: #e64a19; /* Darker orange on hover */
  box-shadow: 0 6px 30px rgba(255, 87, 34, 0.7); /* Enhanced shadow on hover */
}

.modal-content a {
  color: #ff7300; /* Orange for links */
  text-decoration: none;
  font-weight: bold;
}

.modal-content a:hover {
  text-decoration: underline; /* Underline on hover for better UX */
}