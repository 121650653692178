/* Ensure no default spacing around the page */
html, body {
    margin: 0;
    padding: 0;
    height: 100%; /* Ensure body takes full height */
  }
  
  /* Background container */
  .login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Center content vertically */
    height: 100vh; /* Full viewport height */
    text-align: center;
    background-image: url('../crowd3.jpg'); /* Replace with your image path */
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent tiling */
    position: relative;
    color: #fff;
    margin: 0; /* Remove any unwanted margin */
    padding: 0;
    overflow: hidden;
  }
    
  .login-logo {
    position: absolute;
    top: 32.9px; /* Adjust spacing from the top */
    left: 50%;
    transform: translateX(-50%);
    z-index: 2; /* Ensure it stays above the overlay */
  }
  
  /* Overlay to darken background */
  .login-page-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  /* Content container */
  .login-page-content {
    margin-top: 50px;
    position: relative;
    z-index: 2;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
  }
  
  /* Title styling */
  .login-page-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 2rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
  
  /* Subtitle styling */
  .login-page-subtitle {
    font-size: 1.25rem;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
  }
  
  .festival-list-container {
    max-height: 780px; /* Ensure there's enough height to allow scrolling */
    height: 90%; /* Ensure it takes full available space */
    width: 100vw; /* Full width of the viewport */
    overflow-y: auto; /* Enable vertical scrolling */
    position: relative; /* Maintain positioning */
    background-color: transparent; /* Make the background invisible */
    display: flex; /* Use flexbox for alignment */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: flex-start; /* Start items at the top */
    margin-bottom: 20px; /* Add bottom margin here */
    padding: 0; /* Remove padding for invisibility */
  }
  
  .festival-list-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome/SaFfari */
  }
  
  .festival-list-item {
    display: flex; /* Use flexbox for individual items */
    justify-content: center; /* Center the button horizontally */
    width: 100%; /* Full width for alignment */
  }
  
  /* Button styling */
  .login-page-button {
    background-color: #ff7300;
    color: #fff;
    font-weight: bold;
    align-items: center;
    left: 50%;
    justify-content: center;
    padding: 10px 30px;
    border-radius: 30px;
    box-shadow: 0 4px 20px rgba(255, 87, 34, 0.5);
    transition: all 0.3s ease-in-out;
    border: none; /* Ensure no borders */
    cursor: pointer;
    width: 300px; /* Set a fixed width */
    text-align: center;
    margin: 10px 0; /* Add spacing between buttons */
    /* Ensure text is centered within the button */

  }
  
  .login-page-button:hover {
    background-color: #e64a19;
    box-shadow: 0 6px 30px rgba(255, 87, 34, 0.7);
  }
  
  .login-page-logo {
    position: absolute;
    top: 0; /* Place logo near the top */
    left: 50%;
    transform: translateX(-50%);
    z-index: 2; /* Ensure it stays above the overlay */
  }
  
  .login-page-logo svg {
    height: 70px; /* Adjust logo size */
    margin-top: 40px;
  }

  .login-disconnect-button {
    position: fixed; /* Keeps it in the same spot relative to the viewport */
    bottom: 20px; /* Space from the top */
    right: 20px; /* Space from the right */
    font-weight: bold;
    background-color: #ff7300;
    box-shadow: 0 4px 20px rgba(255, 87, 34, 0.5);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    z-index: 3; /* Ensure it's above the overlay */
    transition: background-color 0.3s ease; /* Smooth hover effect */
  }
  
  .login-disconnect-button:hover {
    background-color: #b71c1c; /* Darker red on hover */
  }

  .header {
    background-color: #000; /* Black background */
    padding: 70px 20px; /* Padding around the content */
    display: flex; /* Use flexbox for alignment */
    justify-content: space-between; /* Space between logo and button */
    align-items: center; /* Center items vertically */
    position: fixed; /* Fix the header at the top */
    top: 0; /* Stick to the top */
    width: 100%; /* Full width */
    z-index: 1000; /* Stay on top of other elements */
    box-shadow: 0px 41px 25px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
  }