.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Ensure no default spacing around the page */
html, body {
  margin: 0;
  padding: 0;
  height: 100%; /* Ensure body takes full height */
}

/* Background container */
.app-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Center content vertically */
  height: 100vh; /* Full viewport height */
  text-align: center;
  position: relative;
  color: #000000;
  margin: 0; /* Remove any unwanted margin */
  padding: 0;
  overflow: hidden;
}

/* Overlay to darken background */
.app-page-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

/* Content container */
.app-page-content {
  margin-top: 120px;
  position: relative;
  z-index: 2;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
}

/* Title styling */
.app-page-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

/* Subtitle styling */
.app-page-subtitle {
  font-size: 1.25rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}

.app-list-container {
  max-height: 740px; /* Ensure there's enough height to allow scrolling */
  height: 90%; /* Ensure it takes full available space */
  width: 100vw; /* Full width of the viewport */
  overflow-y: auto; /* Enable vertical scrolling */
  position: relative; /* Maintain positioning */
  background-color: transparent; /* Make the background invisible */
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: flex-start; /* Start items at the top */
  margin-bottom: 20px; /* Add bottom margin here */
  padding: 0; /* Remove padding for invisibility */
}

.app-list-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome/SaFfari */
}

.app-list-item {
  display: flex; /* Use flexbox for individual items */
  justify-content: center; /* Center the button horizontally */
  width: 100%; /* Full width for alignment */
}

/* Button styling */
.app-page-button {
  background-color: #ff7300;
  color: #fff;
  font-weight: bold;
  align-items: center;
  left: 50%;
  justify-content: center;
  padding: 10px 30px;
  border-radius: 30px;
  box-shadow: 0 4px 20px rgba(255, 87, 34, 0.5);
  transition: all 0.3s ease-in-out;
  border: none; /* Ensure no borders */
  cursor: pointer;
  width: 300px; /* Set a fixed width */
  text-align: center;
  margin: 10px 0; /* Add spacing between buttons */
  /* Ensure text is centered within the button */

}

.app-page-button:hover {
  background-color: #e64a19;
  box-shadow: 0 6px 30px rgba(255, 87, 34, 0.7);
}

.app-page-logo {
  position: absolute;
  top: 0; /* Place logo near the top */
  left: 50%;
  transform: translateX(-50%);
  z-index: 2; /* Ensure it stays above the overlay */
}

.app-page-logo svg {
  height: 70px; /* Adjust logo size */
  margin-top: 40px;
}

